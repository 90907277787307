// entry point for integration loader helper script
// -> loads the main integration and or iframe scripts depending on detected elements
((window: Window, document: Document) => {
  const scriptId = 'nc-sdk-loader';
  const elementClassName = 'nc-sdk';

  const createHeadScript = (id: string, src: string) => {
    const existing = document.getElementById(id);
    if (existing) {
      existing.remove();
    }
    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.type = "module";
    document.head.appendChild(script);
  };

  const handleLoad = () => {
    console.log('[NC-SDK] Loading...');
    const loaderScript = document.getElementById(scriptId) as HTMLScriptElement;
    if (!loaderScript) {
      console.warn(`[NC-SDK] Loader script with ID ${scriptId} not found`);
      return;
    }
    const loaderScriptUrl = new URL(loaderScript.src);
    const baseUrl = loaderScriptUrl.origin;

    const sdkElements = document.getElementsByClassName(elementClassName);

    let hasIframe = false;
    let hasIntegration = false;

    for (const element of sdkElements) {
      const htmlElement = element as HTMLElement;
      const dataset = htmlElement.dataset;
      if (dataset.type === 'iframe') {
        hasIframe = true;

        const params = new URLSearchParams();
        const applyParam = (key: string) => {
          if (dataset[key]) {
            params.set(key, dataset[key]!);
          }
        };
        applyParam('component');
        applyParam('platform');
        applyParam('accountId');
        applyParam('filter');
        applyParam('theme');
        applyParam('recaptcha');

        const iframe = document.createElement('iframe');
        iframe.classList.add('nc-sdk-iframe');
        iframe.src = `${baseUrl}/${dataset.language || 'de'}/sdk/?${params.toString()}`;
        iframe.title = 'NC SDK Integration';

        Object.getOwnPropertyNames(dataset)
          .filter((key) => key.startsWith('attribute'))
          .forEach((key) => {
            const attribute = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
            iframe.setAttribute(attribute.replace('attribute-', ''), dataset[key] || '');
          });

        element.innerHTML = '';
        element.appendChild(iframe);
      } else {
        hasIntegration = true;
      }
    }

    if (hasIframe) {
      console.log('[NC-SDK] Loading iframe integration');
      createHeadScript('nc-sdk-iframe-script', `${baseUrl}/scripts/sdk/iframe.js`);
    }

    if (hasIntegration) {
      console.log('[NC-SDK] Loading direct integration');
      createHeadScript('nc-sdk-polyfills-script', `${baseUrl}/scripts/sdk/polyfills.js`);
      createHeadScript('nc-sdk-main-script', `${baseUrl}/scripts/sdk/index.js`);
    }
  };

  console.log(document.readyState);
  if (document.readyState === 'loading') {
    window.addEventListener('load', handleLoad);
  } else {
    handleLoad();
  }
})(window, window.document);
